<template>
  <div class="container">
    <div class="container_self">
      <header class="pub_header">
          <div class="pub_logo">AI Publish</div>
          <nav class="index_menu">
              <ul>
                  <!-- <li>About us</li>
                  <li>Solution</li>
                  <li>Pricing</li> -->
              </ul>
          </nav>
          <div class="index_auth-buttons">
              <router-link to="/PublishLogin">
                <el-button>Login</el-button>
              </router-link>
              <router-link to="/PublishRegister">
                <el-button>Start Publishing</el-button>
              </router-link>
          </div>
      </header>
    </div>
  </div>
</template>
<script>
export default {
  name:"PublishIndex",
  data(){
    return{

    }
  },
  methods:{

  }
}
</script>
<style lang="less" scoped>
.container_self{
    background-image: url('http://www.homywork.com/images/index_bg2.jpg');
    background-size: cover;         /* 背景图自适应容器 */
    background-repeat: no-repeat;   /* 不重复背景图 */
    background-position: center;    /* 背景图居中显示 */
    width: 100%;                    /* 宽度100% */
    height: 100vh;                  /* 高度100% */
    display: flex;                  /* 使用 Flexbox */
    justify-content: center;        /* 水平居中 */
    align-items: center;            /* 垂直居中 */
    position: relative;
}
.pub_header {
    position: absolute;             /* 使绝对定位的元素相对于该元素定位 */
    top: 50px;                     /* 从顶部下移100px */
    left: 0px;
    z-index: 3;                     
    display: flex;                  /* 使用 Flexbox 布局 */
    justify-content: space-between;  /* 在三部分之间添加空间 */
    align-items: center;            /* 垂直居中对齐 */
    width: 100%;                    /* 头部宽度100% */
    
}

.pub_logo {
    flex: 1;                        /* 左侧logo占据部分 */
    text-align: center;
    color: #ffffff;
    font-size: 38px;
    font-weight: 800;
}

.index_menu {
    flex: 2;                        /* 中间菜单占据部分 */
    text-align: center;
    ul {
        list-style: none;              /* 去掉列表样式 */
        display: flex;                 /* 使用 Flexbox 来水平排列菜单项 */
        justify-content: center;       /* 居中菜单项 */
        padding: 0;                    /* 去掉内边距 */
        margin: 0;                     /* 去掉外边距 */

        li {
            padding: 0 15px;                /* 菜单项之间间距 */
            display: inline-block;
            width: 15%;
            color: #ffffff;
            font-size: 22px;
            font-weight: bold;
        }
    }

    
}

.index_auth-buttons {
    flex: 1;                        /* 右侧登录和注册按钮占据部分 */
    display: flex;                 /* 水平排列按钮 */
    justify-content: center;     
    text-align: center;

    .el-button {
        margin-left: 20px;             /* 按钮之间间距 */
        height: 40px;
        line-height: 40px;
        background-color: #ffffff;
        color: #111111;
        font-size: 20px;
        font-weight: bold;
    }
}


</style>